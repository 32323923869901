import React, { createContext, useReducer } from 'react';
import initialState from './initialState';
import types from './types';

const store = createContext(initialState);
const { Provider } = store;
const { 
	SET_CURRENT_USER, 
	SET_TIME_TRACKING, 
	SET_IS_TIME_TRACKING_SAVED, 
	SET_SETTINGS,
	SET_USER_CLEAR_NOTIFICATION
} = types;

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    const { type, payload } = action;

    switch(type) {
      case SET_CURRENT_USER:
        return {
          ...state,
          currentUser: {
            ...payload
          },
				}
			case SET_TIME_TRACKING:
				return {
					...state,
					timeTracking: {
						...payload
					},	
				}	
			case SET_IS_TIME_TRACKING_SAVED:
				return {
					...state,
					isTimeTrackingSaved: payload,	
				}	
			case SET_IS_TIME_TRACKING_SAVED:
				return {
					...state,
					isLoggedIn: payload,	
				}	
			case SET_SETTINGS:
				return {
					...state,
					settings: {
						...payload
					},
				}
			case SET_USER_CLEAR_NOTIFICATION:
				return {
					...state,
					userClearNotification: payload,
				}			
    };
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>
};

export { store, ContextProvider };