import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
// import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import Switch from '@mui/material/Switch';
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import { useEffect } from 'react';

export default function ModalChangeCompany({ open, setOpen, customer_default, customer_users, setCurrentUser, user }) {
    const [fullWidth, setFullWidth] = useState(true);
    const [selected, setSelected] = useState(customer_default);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleOk = (e) => {
        setCurrentUser({
            ...user,
            customerId: selected
        })
        setOpen(false)
    };

    const handleChange = (event) => {
        setSelected(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value,
        );
    };

    useEffect(() => {
        setSelected(customer_default)
    }, [customer_default])


    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen}>
                Open max-width dialog
            </Button>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={"sm"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Cambiar empresa</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Seleccione con cuál empesa desea trabajar
                    </DialogContentText>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: '100%',
                        }}
                    >
                        {!!customer_default &&
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel htmlFor="cliente">Cliente</InputLabel>
                                <Select
                                    autoFocus
                                    value={selected}
                                    onChange={handleChange}
                                    label="cliente"
                                    inputProps={{
                                        name: 'cliente',
                                        id: 'cliente',
                                    }}
                                    size='small'
                                >
                                    {map(sortBy(customer_users, ["customer.name"]), ({ customer }) => (
                                        <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }
                    </Box>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 3 }}>
                    <Button variant='outlined' onClick={handleClose}>Cerrar</Button>
                    <Button variant='contained' onClick={handleOk}>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}
