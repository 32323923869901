export const BASE_URL = process.env.REACT_APP_API_URL;
export const LICENCES_URL = process.env.REACT_APP_LICENCES_URL;
export const CHECK_LICENCE = 'api/checkLicence';
export const GET_LICENSE_NUMBER = 'api/getLicenseNumber';
export const LOGIN = 'api/login';
export const LOGOUT = 'api/logout';
export const GET_COUNTRES = 'api/countries';
export const GET_BUSINESS_TYPES = 'api/businessTypes';
export const GET_CLIENT_CODE = 'api/getClientCode';
export const GET_CUSTOMERS = 'api/customers';
export const GET_AVAILABLE_CUSTOMERS = 'api/customers/getAvailableCustomers';
export const CREATE_CLIENT = 'api/newCustomer';
export const SET_USER_TO_CUSTOMER = 'api/customer/createUser';
export const SAVE_AVATAR = 'api/saveAvatar';
export const GET_CUSTOMER = (id) => `api/customer/${id}`;
export const SAVE_DOCUMENT = 'api/saveDocument';
export const DELETE_DOCUMENT = 'api/deleteDocument';
export const GET_USERS = 'api/users';
export const GET_CUSTOMERS_FOR_SELECT = 'api/customersForSelect';
export const GET_UNITS = 'api/units';
export const GET_LAWYERS = 'api/getLawyers';
export const GET_AUDITS = 'api/audits';
export const NEW_COUNTRY = 'api/newCountry';
export const NEW_UNIT = 'api/newUnit';
export const NEW_AUDIT = 'api/newAudit';
export const GET_COURTS = 'api/courts';
export const NEW_COURT = 'api/newCourt';
export const NEW_STAGE = 'api/newStage';
export const NEW_SUB_STAGE = 'api/newSubStage';
export const GET_JURISDICTION = 'api/jurisdictions';
export const NEW_JURISDICTION = 'api/newJurisdiction';
export const GET_MUNICIPALITIES = 'api/municipalities';
export const NEW_MUNICIPALITY = 'api/newMunicipality';
export const GET_PROCESSES_TYPES = 'api/typesProcesses';
export const NEW_PROCESSES_TYPE = 'api/newtypesProcesses';
export const GET_SUB_TYPES = 'api/subTypes';
export const NEW_SUB_TYPE = 'api/newSubType';
export const NEW_BUSINESS_TYPE = 'api/newBusinessType';
export const DELETE_AUDIT = 'api/deleteAudit';
export const DELETE_BUSINESS_TYPE = 'api/deleteBusinessType';
export const DELETE_COUNTRY = 'api/deleteCountry';
export const DELETE_COURT = 'api/deleteCourt';
export const DELETE_JURISDICTION = 'api/deleteJurisdiction';
export const DELETE_SUB_TYPE = 'api/deleteSubType';
export const DELETE_UNIT = 'api/deleteUnit';
export const DELETE_PROCESSES_TYPE = 'api/deleteProcessType';
export const DELETE_MUNICIPALITY = 'api/deleteMunicipality';
export const UPDATE_AUDIT = 'api/updateAudit';
export const UPDATE_BUSINESS_TYPE = 'api/updateBusinessType';
export const UPDATE_COUNTRY = 'api/updateCountry';
export const UPDATE_COURT = 'api/updateCourt';
export const UPDATE_JURISDICTION = 'api/updateJurisdiction';
export const UPDATE_SUB_TYPE = 'api/updateSubType';
export const UPDATE_UNIT = 'api/updateUnit';
export const UPDATE_PROCESSES_TYPE = 'api/updateProcessType';
export const UPDATE_MUNICIPALITY = 'api/updateMunicipality';
export const UPDATE_JUDGMENT_STAGE = 'api/updateStage';
export const UPDATE_SUB_STAGE = 'api/updateSubStage';
export const NEW_USER = 'api/newUser';
export const EDIT_USER = 'api/updateUser';
export const GET_ROLES = 'api/roles';
export const SAVE_USER_AVATAR = 'api/saveUserAvatar';
export const DELETE_USER = 'api/deleteUser';
export const GET_ORDERS = 'api/orders';
export const CREATE_QUOTE = 'api/newQuote';
export const GET_QUOTE_CODE = (type) => `api/getQuoteCode/${type}`;
export const GET_QUOTE = (id) => `api/order/${id}`;
export const ADD_TRACKING = (id) =>  `api/order/${id}/addTracking`;
export const ADD_EXPENSE = (id) =>  `api/order/${id}/addExpense`;
export const ADD_BILL = (id) =>  `api/order/${id}/addBill`;
export const NEW_BILL = 'api/newBill';
export const EDIT_BILL = (id) =>  `api/bill/${id}/update`;
export const DELETE_BILL = (id) =>  `api/bill/${id}/destroy`;
export const MARK_BILL_AS_PAY = (id) =>  `api/bill/${id}/markAsPay`;
export const CREATE_JUDGMENT = 'api/newJudgment';
export const GET_JUDGMENT_TYPE = 'api/judgmentTypes';
export const NEW_JUDGMENT_TYPE = 'api/newJudgmentTypes';
export const GET_STAGES = 'api/stages';
export const GET_SUB_STAGES = 'api/subStages';
export const ADD_STAGE = (id) =>  `api/order/${id}/addStage`;
export const GET_ORDER_DATA = 'api/order/getData';
export const GET_BILLS_DATA = 'api/bill/getData';
export const GET_BILLS = 'api/bills';
export const DELETE_TRACKING = (id) =>  `api/tracking/${id}/destroy`;
export const GET_TRACKINGS = `api/trackings`;
export const GET_LOGS = 'api/logs';
export const GET_QUOTE_STAGES = (orderId) => `api/quoteStages/${orderId}`;
export const DELETE_SUB_STAGE = (id) =>  `api/stageSubStage/${id}/destroy`;
export const DELETE_QUOTE_STAGE = (id, stageId) =>  `api/quote/${id}/stages/${stageId}/destroy`;
export const NEW_TIME = 'api/newTime';
export const GET_TIMES = 'api/times';
export const DELETE_TIME = (id) =>  `api/time/${id}/destroy`;
export const EDIT_TIME = (id) =>  `api/time/${id}/update`;
export const GET_NOTIFICATIONS = 'api/notifications';
export const GET_ALL_NOTIFICATIONS = 'api/allNotifications';
export const CLEAR_NOTIFICATION = (id) => `api/notifications/${id}/clear`;
export const CLEAR_NOTIFICATIONS = 'api/notifications/clearAll';
export const SEARCH_RESULTS = 'api/search';
export const SEARCH_CUSTOMER_FILES = 'api/searchCustomerFiles';
export const SEARCH_CUSTOMER_SHARED_FILES = 'api/searchCustomerSharedFiles';
export const DELETE_CLIENT = (id) =>  `api/customer/${id}/destroy`;
export const UPDATE_CLIENT = (id) =>  `api/customer/${id}/update`;
export const GENERATE_BILL = (id) =>  `api/quote/${id}/generateBill`;
export const GENERATE_JUDGMENT_BOOK = (id) =>  `api/judgment/${id}/generateJudgmentBook`;
export const GET_FOLDERS = (code) =>  `api/customer/${code}/getFolders`;
export const GET_FILES_IN_FOLDER  = 'api/customer/getFilesInFolder';
export const DELETE_QUOTE = (id) =>  `api/order/${id}/destroy`;
export const GET_ALERTS = 'api/alerts';
export const COMPLETE_QUOTE = (id) =>  `api/order/${id}/complete`;
export const REOPEN_QUOTE = (id) =>  `api/order/${id}/reopen`;
export const DOWNLOAD_JUDGMENT_BOOK = 'api/judgment/downloadBook';
export const UPDATE_QUOTE = (id) =>  `api/order/${id}/update`;
export const GET_ORDER_REPORTS_DATA = 'api/reports/quotesData';
export const GET_BILLS_REPORTS_DATA = 'api/reports/billsData';
export const DELETE_EXPENSE = (id) =>  `api/expense/${id}/destroy`;
export const UPDATE_EXPENSE = (id) =>  `api/expense/${id}/update`;
export const UPDATE_STAGE = (id) =>  `api/quoteStage/${id}/update`;
export const UPDATE_TRACKING = (id) =>  `api/tracking/${id}/update`;
export const GET_ACTIVE_USERS = 'api/users/getActiveUsers';
export const SHARE_BILLS = (id) =>  `api/order/${id}/shareBills`;
export const SHARE_FILE = 'api/shareFile';
export const UNSHARE_FILE = 'api/unshareFile';
export const GET_FILES = 'api/customer/getAllFiles';
export const GET_SHARED_FILE = 'api/customer/getSharedFile';
export const CREATE_FOLDER = 'api/customer/createFolder';
export const CHANGE_PASSWORD = 'api/changePassword';
export const SAVE_SETTINGS = 'api/settings/save';
export const GET_SETTINGS = 'api/settings';
export const EXPORT_LOGS = 'api/reports/exportLogs';
export const GET_PUBLIC_SETTINGS = 'api/settings/public';
export const GET_CURRENCIES = 'api/currencies';
export const NEW_CURRENCY = 'api/newCurrency';
export const UPDATE_CURRENCY = 'api/updateCurrency';
export const DELETE_CURRENCY = 'api/deleteCurrency';
export const CREATE_TASK = 'api/task/new';
export const GET_TASKS = 'api/tasks';
export const DELETE_TASK = (id) =>  `api/task/${id}/destroy`;
export const END_TASK = (id) =>  `api/task/${id}/complete`;
export const EXPORT_TIMES = 'api/reports/exportTimesData';
export const EXPORT_TIMES_BY_LAWYER = 'api/reports/exportTimesByLawyerData';
export const EXPORT_TIMES_COMPLETE_BY_LAWYER = 'api/reports/exportTimesByLawyerCompleteData';
export const EXPORT_TIMES_COMPLETE_BY_LAWYER_BY_CLIENT = 'api/reports/exportTimesByLawyerByClientCompleteData';
export const EXPORT_GENERAL_BILLS = 'api/reports/exportGeneralBillsData';
export const EXPORT_BILLS = 'api/reports/exportBillsData';
export const EXPORT_CLIENTS = 'api/reports/exportClientsData';
export const EXPORT_QUOTES = 'api/reports/exportQuotes';
export const EXPORT_ORDERS = 'api/reports/exportQuotesData';
export const EXPORT_ORDER = 'api/reports/exportQuoteData';
export const EXPORT_ORDER_BILLS = 'api/reports/exportQuoteBillsByClientData';
export const EXPORT_QUOTES_PREVIEW = 'api/reports/preview/exportQuotesData';
export const EXPORT_BILLS_PREVIEW = 'api/reports/preview/exportBillsData';
export const EXPORT_CUSTOMERS_PREVIEW = 'api/reports/preview/exportClientsData';
export const GET_USER = (id) =>  `api/users/${id}`;
export const VAL_EMAIL = (mail) => `api/users/exists/${mail}`
export const EXPORT_TIMES_PREVIEW = 'api/reports/preview/exportTimesData';
export const EXPORT_TIMES_GENERAL_DATA = 'api/reports/exportTimesGeneralData';
export const GET_ORDERS_FOR_SELECT = 'api/ordersForSelect';
export const EXPORT_JUDGMENTS = 'api/reports/exportJudgments';
export const EXPORT_SENT_TO_BILL_JUDGMENTS = 'api/reports/exportSentToBillJudgments';
export const EXPORT_SENT_BILL_PENDING_JUDGMENTS = 'api/reports/exportBillPendingJudgments';
export const SHARE_ALL_DOCS = (type, id) =>  {
    if (type === "Orden") return `api/trackings/${id}/shareDocs`;
    if (type === "Juicio") return `api/stages/${id}/shareDocs`;
}

export const EXPORT_TASKS = 'api/reports/exportTasks';
export const VALIDATE_LICENSE = 'api/settings/validateLicense';
export const EDIT_FOLDER = 'api/customer/editFolder';