import React, { useState, useEffect, useContext, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiDrawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import MuiAppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CircularProgress from '@mui/material/CircularProgress';
import { useStopwatch } from 'react-timer-hook';
import {
	QueryClient,
	QueryClientProvider,
} from 'react-query'
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import { store } from './context/ContextProvider';
import types from './context/types';

import useToken from './_hooks/useToken';
import useCurrentUser from './_hooks/useCurrentUser';
import { postData, getData } from './api/baseClient';
import {
	NEW_TIME,
	GET_PUBLIC_SETTINGS,
} from './constants/endpoints';
import {
	ERROR_MSG
} from './constants/errorMessages';
import {
	ROLES
} from './constants/permisions';

import './App.css';
import 'react-dropzone-uploader/dist/styles.css';

import Modal from "./components/Modal"

import blankImg from './assets/images/blankImg.png';
import ScrollToTop from './components/ScrollToTop';

const Alert = lazy(() => import('./components/Alert'));
const Header = lazy(() => import('./components/Header'));
const Menu = lazy(() => import('./components/Menu'));

const Login = lazy(() => import('./pages/login'));
const Dashboard = lazy(() => import('./pages/dashboard'));
const Clients = lazy(() => import('./pages/clients'));
const Users = lazy(() => import('./pages/users'));
const UserDetail = lazy(() => import('./pages/users/show'));
const NewClient = lazy(() => import('./pages/clients/new'));
const Profile = lazy(() => import('./pages/clients/profile'));
const Quotes = lazy(() => import('./pages/quotes'));
const Quote = lazy(() => import('./pages/quotes/quote'));
const Times = lazy(() => import('./pages/times'));
const Bills = lazy(() => import('./pages/bills'));
const Reports = lazy(() => import('./pages/reports'));
const ABC = lazy(() => import('./pages/abc'));
const Logs = lazy(() => import('./pages/logs'));
const Notifications = lazy(() => import('./pages/notifications'));
const SearchResults = lazy(() => import('./pages/searchResults'));
const SharedFiles = lazy(() => import('./pages/sharedFiles'));
const Settings = lazy(() => import('./pages/settings'));
const Terms = lazy(() => import('./pages/terms'));
const NotFound = lazy(() => import('./pages/404'));
const CustomerUser = lazy(() => import('./pages/users/newClientUser'));

const { SET_CURRENT_USER, SET_TIME_TRACKING, SET_IS_TIME_TRACKING_SAVED, SET_SETTINGS } = types;
const { SUPPORT, CLIENT, BILLER } = ROLES;
const SUCCESS_MESSAGE = 'Operacion realizada satisfactoriamente';
const ERROR_500 = 'Problemas de conexion';
const ERROR_403 = 'Completar los campos requeridos o Revisa que los campos sean correctos';

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(6)} + 1px)`,
	},
});

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: theme.spacing(0, 1),
	width: '100%',
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		top: '65px',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
);

const queryClient = new QueryClient();

const App = () => {
	const [alertType, setAlertType] = useState('');
	const [alertMessage, setAlertMessage] = useState('');
	const [isAlertVisible, setIsAlertVisible] = useState(false);
	const [open, setOpen] = useState(true);
	const { token, setAuthToken } = useToken();
	const theme = useTheme();
	const { user, setCurrentUser } = useCurrentUser();
	const [openModal, setOpenModal] = useState(false);
	const [isClient, setIsClient] = useState(false);
	const [client, setClient] = useState({});

	const {
		seconds,
		minutes,
		hours,
		isRunning,
		start,
		pause,
		reset,
	} = useStopwatch({ autoStart: false });
	const { state: { timeTracking, settings }, dispatch } = useContext(store);

	useEffect(() => {
		if (!settings) {
			getData(GET_PUBLIC_SETTINGS, {}, null, null).then(({ data: { settingsData } }) => {
				dispatch({ type: SET_SETTINGS, payload: settingsData });
			}).catch((error) => {
				setAlertMessage(ERROR_MSG.GET_SETTINGS);
				setAlertType('error');
				setIsAlertVisible(true);
			});
		}
	}, []);

	useEffect(() => {
		dispatch({ type: SET_CURRENT_USER, payload: user });
		if (user?.role?.name === CLIENT) {
			setIsClient(true);
		} else {
			setIsClient(false);
		}
	}, [user]);

	useEffect(() => {
		const runningHours = hours <= 9 ? `0${hours}` : `${hours}`;
		const runningMinutes = minutes <= 9 ? `0${minutes}` : `${minutes}`;
		const runningSeconds = seconds <= 9 ? `0${seconds}` : `${seconds}`;
		const runningTime = `${runningHours}:${runningMinutes}:${runningSeconds}`;

		const payload = {
			...timeTracking,
			isRunning,
			hours,
			minutes,
			seconds,
			start,
			pause,
			reset,
			runningTime
		};

		dispatch({ type: SET_TIME_TRACKING, payload });
	}, [hours, minutes, seconds]);

	useEffect(() => {
		saveTimeTracking();
	}, [timeTracking?.endAtFormatted]);

	useEffect(() => {
		if (timeTracking?.isStopped) {
			reset();
			pause();
		}
	}, [timeTracking?.isStopped])

	const saveTimeTracking = () => {
		const timeData = {
			client: timeTracking?.client,
			requirement: timeTracking?.requirement,
			description: timeTracking?.description,
			registerDate: timeTracking?.registerDate,
			startAtFormatted: timeTracking?.startAtFormatted,
			endAtFormatted: timeTracking?.endAtFormatted,
			isBillable: timeTracking?.isBillable,
		}

		if (timeTracking?.client && timeTracking?.requirement) {
			postData(NEW_TIME, timeData, token, {}).then(({ data: { data } }) => {
				setAlertMessage(SUCCESS_MESSAGE);
				setAlertType('success');
				setIsAlertVisible(true);
				dispatch({
					type: SET_TIME_TRACKING,
					payload: {
						...timeTracking,
						requirement: null,
						client: null,
						description: null,
						registerDate: null,
						startAtFormatted: null,
						endAtFormatted: null,
						runningTime: '00:00:00',
						isStopped: true,
						quoteType: null,
					}
				});

				dispatch({
					type: SET_IS_TIME_TRACKING_SAVED,
					payload: timeTracking?.isSaved,
				});

			}).catch((e) => {
				const error = { e };
				switch (error?.e?.response?.status) {
					case 403:
						setAlertMessage(ERROR_403);
						break;
					case 500:
						setAlertMessage(ERROR_500);
						break;
					default:
						setAlertMessage(ERROR_500);
						break;
				}
				setAlertType('error');
				setIsAlertVisible(true);
				timeTracking?.pause();
			});
		}
	}

	const getUserAvatar = (avatar) => {
		return avatar ? avatar : blankImg;
	};

	const onErrorImage = (e) => {
		e.target.src = blankImg;
	};


	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const getRoutesByRole = () => {
		let routes;

		if (user?.role?.name === CLIENT) {
			routes = (
				<Switch>
					<Route exact path="/">
						<SharedFiles />
					</Route>
					<Route exact path="/documentos-compartidos">
						<SharedFiles user={user} />
					</Route>
					<Route exact path="/mi-expediente">
						<Profile setCurrentUser={setCurrentUser} />
					</Route>
					<Route path="*">
						<NotFound />
					</Route>
				</Switch>
			)
		} else if (user?.role?.name === SUPPORT) {
			routes = (
				<Switch>
					<Route exact path="/">
						<Quotes />
					</Route>
					<Route exact path="/ordenes-de-trabajo">
						<Quotes />
					</Route>
					<Route exact path="/ordenes-de-trabajo/:id/:action?">
						<Quote />
					</Route>
					<Route exact path="/juicios">
						<Quotes />
					</Route>
					<Route exact path="/nuevo-juicio">
						<Quote />
					</Route>
					<Route exact path="/juicios/:id">
						<Quote />
					</Route>
					<Route exact path="/expedientes">
						<Clients />
					</Route>
					<Route exact path="/cliente/:id">
						<NewClient />
					</Route>
					<Route path="*">
						<NotFound />
					</Route>
				</Switch>
			)
		} else if (user?.role?.name === BILLER) {
			routes = (
				<Switch>
					<Route exact path="/">
						<Bills />
					</Route>
					<Route exact path="/expedientes">
						<Clients />
					</Route>
					<Route exact path="/cliente/:id">
						<NewClient />
					</Route>
					<Route exact path="/facturacion">
						<Bills />
					</Route>
					<Route exact path="/ordenes-de-trabajo">
						<Quotes />
					</Route>
					<Route exact path="/ordenes-de-trabajo/:id/:action?">
						<Quote />
					</Route>
					<Route exact path="/juicios">
						<Quotes />
					</Route>
					<Route exact path="/nuevo-juicio">
						<Quote />
					</Route>
					<Route exact path="/juicios/:id">
						<Quote />
					</Route>
					<Route path="*">
						<NotFound />
					</Route>
				</Switch>
			)
		} else {
			routes = (
				<Switch>
					<Route exact path="/">
						<Dashboard />
					</Route>
					<Route exact path="/dashboard">
						<Dashboard />
					</Route>
					<Route exact path="/adminstracion">
						<Dashboard />
					</Route>
					<Route exact path="/expedientes">
						<Clients />
					</Route>
					<Route exact path="/clientes">
						<Clients />
					</Route>
					<Route exact path="/nuevo-cliente">
						<NewClient />
					</Route>
					<Route exact path="/mi-expediente">
						<Profile setCurrentUser={setCurrentUser} />
					</Route>
					<Route exact path="/documentos-compartidos">
						<SharedFiles />
					</Route>
					<Route exact path="/cliente/:customerId/resultados-busqueda-documentos/:param">
						<SearchResults />
					</Route>
					<Route exact path="/cliente/:id">
						<NewClient />
					</Route>
					<Route exact path="/cliente/:clientId/nueva-orden">
						<Quote />
					</Route>
					<Route exact path="/nuevo-usuario-cliente">
						<CustomerUser />
					</Route>
					<Route exact path="/usuarios/:type?">
						<Users setCurrentUser={setCurrentUser} />
					</Route>
					<Route exact path="/usuario/:id">
						<UserDetail setCurrentUser={setCurrentUser} />
					</Route>
					<Route exact path="/nuevo-usuario">
						<UserDetail setCurrentUser={setCurrentUser} />
					</Route>
					<Route exact path="/ordenes-de-trabajo">
						<Quotes />
					</Route>
					<Route exact path="/nueva-orden">
						<Quote />
					</Route>
					<Route exact path="/orden-de-trabajo/:quoteId/control-de-tiempos">
						<Times />
					</Route>
					<Route exact path="/juicio/:quoteId/control-de-tiempos">
						<Times />
					</Route>
					<Route exact path="/ordenes-de-trabajo/:id/:action?">
						<Quote />
					</Route>
					<Route exact path="/juicios">
						<Quotes />
					</Route>
					<Route exact path="/nuevo-juicio">
						<Quote />
					</Route>
					<Route exact path="/juicios/:id">
						<Quote />
					</Route>
					<Route exact path="/control-de-tiempos">
						<Times />
					</Route>
					<Route exact path="/facturacion">
						<Bills />
					</Route>
					<Route exact path="/informes">
						<Reports />
					</Route>
					<Route exact path="/unidades">
						<ABC type="units" />
					</Route>
					<Route exact path="/auditorias">
						<Logs />
					</Route>
					<Route exact component={Notifications} path="/notificaciones">
					</Route>
					<Route exact path="/paises">
						<ABC type="countries" />
					</Route>
					<Route exact path="/municipios">
						<ABC type="jurisdictions" />
					</Route>
					<Route exact path="/juzgados">
						<ABC type="courts" />
					</Route>
					<Route exact path="/departamentos">
						<ABC type="municipalities" />
					</Route>
					<Route exact path="/rubros-industriales">
						<ABC type="businessTypes" />
					</Route>
					<Route exact path="/etapas-juicios">
						<ABC type="typesProcesses" />
					</Route>
					<Route exact path="/sub-etapas-juicios">
						<ABC type="subTypesProcesses" />
					</Route>
					<Route exact path="/monedas">
						<ABC type="currencies" />
					</Route>
					<Route exact path="/resultados-busqueda/:param">
						<SearchResults />
					</Route>
					<Route exact path="/configuracion-sistema">
						<Settings />
					</Route>
					<Route path="*">
						<NotFound />
					</Route>
				</Switch>
			)
		}

		return routes;
	}

	const assingCompany = async () => {
		const companies = await sortBy(get(user, "customer_users", []), ["customer.name"]);
		const company = await find(companies, ({ customer }) => customer?.id === user?.customerId)
		const current = {
			name: user.name,
			avatar: company?.customer?.avatar,
			company: company?.customer?.name,
			id: user?.customerId
		}

		if (isEmpty(company)) {
			// si no hace match con customerId entonces elejira la primera de la lista
			const fist = await get(companies, "[0]", {});

			if (!isEmpty(fist)) {
				current.avatar = fist?.customer?.avatar;
				current.company = fist?.customer?.name;
				current.id = fist?.customer?.id;
			}
		}
		setClient(current)
	}

	useEffect(() => {
		assingCompany()
	}, [user.customerId])

	if (!token) {
		return <Login setAuthToken={setAuthToken} setCurrentUser={setCurrentUser} />
	}

	return (
		<QueryClientProvider client={queryClient}>
			<div className="App">
				<>
					<Snackbar
						open={isAlertVisible}
						autoHideDuration={3000}
						onClose={() => setIsAlertVisible(false)}
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }
						}>
						<Alert severity={alertType} sx={{ width: '100%' }} >
							{alertMessage}
						</Alert>
					</Snackbar>
					<Router>
						<ScrollToTop />
						<AppBar position="fixed" open={open}>
							<Header setAuthToken={setAuthToken} setCurrentUser={setCurrentUser} />
						</AppBar>
						<Box sx={{ display: 'flex' }}>
							<Drawer variant="permanent" open={open}>
								<DrawerHeader sx={{ padding: "0px !important " }}>
									{isClient
										? (
											<Button classes="p-0" onClick={() => setOpenModal(true)} variant="text" className="w-full" to={`/usuario/${user?.id}`}>
												<Stack
													direction="row"
													justifyContent="center"
													alignItems="center"
													className="bg-white py-2"
												>
													<img
														src={getUserAvatar(client?.avatar)}
														className="w-12 h-12 rounded-full"
														onError={onErrorImage}
														alt="avatar"
													/>
													<div className='duration-300' style={{ maxWidth: 140, width: 140, opacity: open ? 1 : 0 }} >
														<h4 className="text-black text-sm font-bold whitespace-nowrap">
															{client?.company}
														</h4>
														<h5 className="text-xs text-gray-400 leading-none">
															{user?.role?.name}
														</h5>
													</div>
												</Stack>
											</Button>
										) : (
											<Link className="w-full" to={`/usuario/${user?.id}`}>
												<Stack
													direction="row"
													justifyContent="center"
													alignItems="center"
													spacing={1}
													className="bg-white py-4"
												>
													<img
														src={getUserAvatar(user?.avatar)}
														className="w-12 h-12 rounded-full"
														onError={onErrorImage}
														alt="avatar"
													/>
													<div style={{ maxWidth: 140, width: 140 }} >
														<h4 className="text-black text-sm font-bold whitespace-normal">
															{user?.name}
														</h4>
														<h5 className="text-xs text-gray-400 leading-none">
															{user?.role?.name}
														</h5>
													</div>
												</Stack>
											</Link>
										)}

								</DrawerHeader>
								<Divider />
								<Menu open={open} changeCompany={() => setOpenModal(true)} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />
							</Drawer>
							<Box component="main" sx={{ flexGrow: 1, p: 3 }} className="mt-12">
								{getRoutesByRole()}
							</Box>
						</Box>
					</Router>
				</>
				<Modal
					customer_default={get(client, "id")}
					customer_users={get(user, "customer_users")}
					open={openModal}
					setOpen={setOpenModal}
					setCurrentUser={setCurrentUser}
					user={user}
				/>
			</div>
		</QueryClientProvider>
	);
}

export default App;
