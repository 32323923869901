import { useState } from 'react';
import { AES, enc } from 'crypto-js';

const USER = 'user';

const useCurrentUser = () => {

  const getUser = () => {
    const user = sessionStorage.getItem(USER);
    if(user) {
      return JSON.parse(AES.decrypt(user, 'iClex3').toString(enc.Utf8));
    } else {
      return {};
    }    
  };

  const [user, setUser] = useState(getUser());

  const setCurrentUser = user => {
    if(user) {
      sessionStorage.setItem(USER, AES.encrypt(JSON.stringify(user), 'iClex3').toString());
      setUser({...user});
    } else {
      sessionStorage.removeItem(USER);
      setUser({});
    }
  };

  return {
    setCurrentUser,
    user,
  }
};

export default useCurrentUser;