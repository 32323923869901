export const ROLES = {
	ADMIN: 'Socio Director',
	PARTNER: 'Abogado Socio',
	BILLER: 'Facturador',
	COLLABORATOR: 'Colaborador',
	SUPPORT: 'Personal de Apoyo',
	ASSOCIATE: 'Abogado',
	ACCOUNTING: 'Administrador Contabilidad',
	ADMIN_PARTNER: 'Administracion Operativa',
	CLIENT: 'Cliente',
}

export const clientFolders = [
	{
		name: 'avatars',
		displayName: 'Logos',
		roles: [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.PARTNER, ROLES.ASSOCIATE]
	},
	{
		name: '00_primary_documents',
		displayName: 'Documentos Primarios',
		roles: [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.PARTNER, ROLES.ASSOCIATE]
	},
	{
		name: '01_quotes',
		displayName: 'Ordenes de Trabajo',
		roles: [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.PARTNER, ROLES.ASSOCIATE]
	},
	{
		name: 'tracking_documents',
		displayName: 'Documentos de Seguimientos',
		roles: [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.PARTNER, ROLES.ASSOCIATE]
	},
	{
		name: 'expense_documents',
		displayName: 'Documentos de Gastos',
		roles: [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.PARTNER, ROLES.ASSOCIATE]
	},
	{
		name: 'bills_documents',
		displayName: 'Documentos de Facturacion',
		roles: [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.PARTNER, ROLES.ASSOCIATE]
	},
	{
		name: 'stages_documents',
		displayName: 'Documentos de Etapas',
		roles: [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.PARTNER, ROLES.ASSOCIATE]
	},
	{
		name: '02_judgments',
		displayName: 'Juicios',
		roles: [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.PARTNER, ROLES.ASSOCIATE]
	},
	{
		name: '03_shared_files',
		displayName: 'Documentos Compartidos',
		roles: [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.PARTNER, ROLES.ASSOCIATE, ROLES.CLIENT]
	},
];

export const roleCanCreateRoles = [
	{
		role: ROLES.ADMIN,
		canCreate: [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.PARTNER, ROLES.ASSOCIATE, ROLES.BILLER, ROLES.COLLABORATOR, ROLES.SUPPORT],
	},
	{
		role: ROLES.ADMIN_PARTNER,
		canCreate: [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.PARTNER, ROLES.ASSOCIATE, ROLES.BILLER, ROLES.COLLABORATOR, ROLES.SUPPORT],
	},
	{
		role: ROLES.PARTNER,
		canCreate: [ROLES.PARTNER, ROLES.ASSOCIATE, ROLES.BILLER, ROLES.COLLABORATOR, ROLES.SUPPORT],
	},
	{
		role: ROLES.ASSOCIATE,
		canCreate: [ROLES.PARTNER, ROLES.ASSOCIATE, ROLES.BILLER, ROLES.COLLABORATOR, ROLES.SUPPORT],
	},
	{
		role: ROLES.BILLER,
		canCreate: [ROLES.BILLER],
	},
	{
		role: ROLES.ACCOUNTING,
		canCreate: [ROLES.BILLER],
	},
	{
		role: ROLES.COLLABORATOR,
		canCreate: [ROLES.COLLABORATOR, ROLES.SUPPORT],
	},
	{
		role: ROLES.SUPPORT,
		canCreate: [ROLES.SUPPORT],
	}
]

export const seeActiveUsers = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.PARTNER, ROLES.ASSOCIATE];
export const seeNewTimeForm = [ROLES.ADMIN, ROLES.BILLER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const createQuotePermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const deleteQuotePermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER];
export const seeQuoteInfo = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER, ROLES.BILLER];
export const seeJudgmentInfo = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const seeTrackingsPermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER, ROLES.SUPPORT];
export const seeBillsPermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.BILLER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const shareBillsPermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.BILLER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const newBillPermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.BILLER];
export const requestBillingPermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const newExpensePermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.BILLER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const addTimePermission = [ROLES.ADMIN, ROLES.BILLER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const deleteTimePermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER];
export const editTimePermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER];  
export const newClientPermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const editClientPermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const deleteClientPermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const seeTimesPermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const seeExpensesPermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.BILLER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const seeClientMediaPermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER, ROLES.SUPPORT];
export const seeTasksPermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER, ROLES.SUPPORT];
export const newTaskPermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const taskActionsPermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const seeQuotesResumePermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const deleteTaskPermissions = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER];
export const seeJudgmentFeeAndStagesInfo = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER, ROLES.BILLER];
export const seeClientQuotesAndBills = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER, ROLES.BILLER];
export const addFolderPermission = [ROLES.ADMIN, ROLES.ADMIN_PARTNER, ROLES.ASSOCIATE, ROLES.PARTNER, ROLES.BILLER, ROLES.SUPPORT];