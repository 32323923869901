const initialState = {
	currentUser: null,
	timeTracking: {
		isRunning: false,
		hours: '00',
		minutes: '00',
		seconds: '00',
		start: () => {},
		pause: () => {},
		reset: () => {},
		startAtFormatted: null,
		endAtFormatted: null,
		registerDate: null,
		client: null,
		requirement: null,
		description: null,
		isPaused: false,
		isStopped: false,
		isBillable: false,
		runningTime: '00:00:00',
		isSaved: false,
		quoteType: null,
	},
	isTimeTrackingSaved: false,
	isLoggedIn: false,
	settings: null,
	userClearNotification: false,
};

export default initialState;