import { useState } from 'react';

const TOKEN = 'token';

const useToken = () => {

  const getToken = () => {
    const token = sessionStorage.getItem(TOKEN);
    return token;
  };

  const [token, setToken] = useState(getToken());

  const setAuthToken = userToken => {
    if(userToken) {
      sessionStorage.setItem(TOKEN, userToken);
      setToken(userToken);
    } else {
      sessionStorage.removeItem(TOKEN);
      setToken(null);
    }
  };

  return {
    setAuthToken,
    token,
    getToken
  }
};

export default useToken;