export const ERROR_MSG = {
	GET_CUSTOMER: 'No se pudo cargar la informacion del cliente',
	GET_CUSTOMERS: 'Hubo problemas cargando los clientes.',
	GET_QUOTES: 'Hubo problemas cargando las OT',
	GET_JUDGMENTS: 'Hubo problemas cargando los juicios',
	GET_CUSTOMERS_DATA: 'Hubo problemas cargando los datos de clientes.',
	GET_QUOTES_DATA: 'Hubo problemas cargando los datos de las OT.',
	GET_ACTIVE_USERS: 'Hubo problemas cargando los usuarios activos.',
	DELETE_QUOTE: 'Hubo problemas elimiando la OT/Juicio. Pruebe nuevamente.',
	REOPEN_QUOTE: 'No se pudo reactivar la OT/Juicio. Pruebe nuevamente.',
	GET_QUOTE_CODE: 'No se pudo obtener el codigo para la OT/Juicio',	
	GET_UNITS: 'Hubo problemas cargando las unidades',
	GET_JUDGMENT_TYPE: 'Hubo problemas cargando el tipo de juicio',
	GET_LAWYERS: 'Hubo problemas cargando los abogados.',
	GET_COUNTRES: 'Hubo problemas cargando los paises.',
	GET_COURTS: 'Hubo problemas cargando los juzgados',
	GET_STAGES: 'Hubo problemas cargando las etapas de juicios',
	GET_SUB_STAGES: 'Hubo poblemas cargando las sub etapas de juicios',
	GET_JUDGMENT: 'No se pudo cargar la informacion del Juicio',
	GET_QUOTE: 'No se pudo cargar la informacion de la OT',
	GET_TIMES: 'Hubo problemas cargado los registros de tiempos',
	GET_BILLS: 'Hubo problemas cargando las facturas',
	CREATE_QUOTE: 'No se pudo crear la OT',
	CREATE_JUDGMENT: 'No se pudo crear el juicio',
	GET_TRACKINGS: 'No se pudieron obtener los seguimientos',
	DELETE_ACTION: 'No se pudo eliminar el registro',
	GET_QUOTE_STAGES: 'No se pudieron obtener las etapas del juicio',
	GENERATE_BILL: 'No se pudo mandar a facturar.',
	GENERATE_JUDGMENT_BOOK: 'No se pudo generara el libro de juicio',
	QUOTE_STATUS: 'No se pudo cambiar el estado de la OT/Juicio',
	SHARE_BILLS: 'Hubo problemas compatiendo la facturacion con el equipo de trabajo',
	GET_CLIENT_CODE: 'No se pudo obtener codigo para el cliente',
	GET_BUSINESS_TYPES: 'Hubo problemas cargando los Rubros industriales',
	GET_FILES_IN_FOLDER: 'Hubo problemas cargando los archivos del folder',
	GET_FOLDERS: 'Hubo problemas cargando los folders del cliente',
	SEARCH_CUSTOMER_FILES: 'Hubo problema cargando los archivos',
	CREATE_FOLDER: 'Hubo problemas creando el folder',
	GET_ROLES: 'No se pudieron obtener los roles',
	GET_USERS: 'Hubo problemas cargando los usuarios',
	SAVE_SETTINGS: 'No se pudo guardar las configuraciones del sistema',
	GET_SETTINGS: 'No se pudo obtener las configuraciones del sistema',
	GET_LOGS: 'No se pudieron obtener los logs del sistema',
	GET_CURRENCIES: 'No se pudieron obtener las monedas',
	GET_TASKS: 'No se pudieron obtener las tareas',
	CREATE_TASK: 'No se pudo crear la tarea',
	END_TASK: 'No se pudo marcar como finalizada la tarea',
	EDIT_FOLDER: 'No se pudo editar la carpeta',
	SHARE_ALL_DOCS: 'No se pudo compartir los documentos'
}