import axios from "axios";
import { BASE_URL } from "../constants/endpoints";

const api = axios.create({ baseURL: BASE_URL });

export const getData = (endpoint, params, token) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
	};

	const queryParams = {
		params: { ...params }
	}

  const response = api.get(endpoint, { ...queryParams, ...config });
  return response;
};

export const postData = async (endpoint, data, token, extraHeaders = null, extraConfig = {}) => {
	try {
		let config = {
			headers: { Authorization: `Bearer ${token}` },
			...extraConfig,
		};
		if(extraHeaders) {
			config = { ...config,  headers: { ...config.headers, ...extraHeaders } }
		}
		const response = await api.post(endpoint, data, config);
		return response;
	} catch (e) {
		console.log(e);
		throw e;
	} 
};
